import React from "react";

export default class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      favorite: this.props.favorite,
    };
    this.handleFav = this.handleFav.bind(this);
  }

  handleFav() {
    let store = localStorage.getItem("favorites");
    let favorites = store ? JSON.parse(store) : {};

    console.log(favorites);
    favorites[this.props.item.id] = !favorites[this.props.item.id];
    console.log(favorites, JSON.stringify(favorites));

    localStorage.setItem("favorites", JSON.stringify(favorites));
    this.setState({ favorite: favorites[this.props.item.id] });
  }

  render() {
    let item = this.props.item;
    return (
      <div className="max-w-sm min-w-min mx-auto bg-white rounded-xl shadow-lg space-x-4 m-4 p-6">
        <a className="text-xl font-medium text-black underline" href={item.Url}>
          <img src={"/screens/" + item.id + ".png"} alt={item.Name} />
        </a>
        <h2>
          <a
            className="text-xl font-medium text-black underline"
            href={item.Url}
          >
            {item.Name}
          </a>
          <button className="p-1" type="button" onClick={this.handleFav}>
            {this.state.favorite ? "🌞" : "🌝"}
          </button>
        </h2>
        <div className="text-gray-500">{item.Medium}</div>
        {item.Patreon ? (
          <div className="text-gray-500 underline">
            <a href={item.Patreon} target="_blank" rel="noreferrer">
              Patreon
            </a>
          </div>
        ) : null}
        {item.Discord ? (
          <div className="text-gray-500 underline">
            <a href={item.Discord} target="_blank" rel="noreferrer">
              Discord
            </a>
          </div>
        ) : null}
        {item.Login === "y" ? (
          <div className="text-gray-500">login req.</div>
        ) : null}
      </div>
    );
  }
}
