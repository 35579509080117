import Tabletop from "tabletop";

let SHEET_CACHE = null;
export default async function getData() {
  if (SHEET_CACHE) {
    // console.log("USING SHEET_CACHE");
    return SHEET_CACHE;
  }

  let data = await Tabletop.init({
    key:
      "https://docs.google.com/spreadsheets/d/1l2a6A1IeBkVOnvog_3y32C9apRxxm3iO_Gt7eobKPi0/edit?usp=sharing",
    simpleSheet: true,
  });
  console.log("tabletop", data);

  if (data) {
    SHEET_CACHE = await data;
    return SHEET_CACHE;
  }

  return [];

  // throw new Error("No data found");
}
