import "./App.css";
import React from "react";

import Game from "./Game";
import getData from "./sheet";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    let store = localStorage.getItem("favorites");
    let favorites = store ? JSON.parse(store) : {};

    this.state = {
      items: [],
      favorites,
    };
  }

  async componentDidMount() {
    let items = await getData();
    this.setState({ items: items.sort((a, b) => (a.id < b.id ? -1 : 1)) });
  }

  render() {
    return (
      <div className="App flex flex-col min-h-screen ">
        <header className="max-w-sm min-w-min mx-auto bg-white rounded-xl shadow-lg space-x-4 m-4 p-6">
          <img src="/logo.png" alt="Incremental Games" />
        </header>
        <main className="grid gap-4 grid-cols-3">
          {this.state.items.map((item, key) => (
            <Game
              item={item}
              favorite={this.state.favorites[item.id]}
              key={key}
            />
          ))}
        </main>
        <footer className="mx-auto center m-4">&copy; Incremental Games</footer>
      </div>
    );
  }
}
